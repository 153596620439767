@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');

.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }
  
  /* Стили для видео */
  .video-container video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: brightness(0.5);
    margin: 0;
  }

  @media (max-aspect-ratio: 16/9) {
    .video-container video {
      width: auto;
      height: 100%;
    }
  }

  .timer-container {
    color: #e4330f;
    margin: auto; /* Центрируем по горизонтали */
    text-align: center; /* Центрируем текст внутри контейнера */
    font-family: "Unbounded";
}

.timer-container span {
    margin-right: 1px;
    margin-left: 1px;
    font-weight: 1000;
    font-size: 80px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .timer-container span {
        font-size: 2.0em; /* Уменьшаем размер шрифта для меньших экранов */
    }
}

@media (max-width: 480px) {
    .timer-container span {
        font-size: 1.5em; /* Дальнейшее уменьшение размера шрифта для очень маленьких экранов */
    }
}

body {
    margin: 0; /* Убираем отступы по умолчанию у body */
    overflow-x: hidden; /* Предотвращаем горизонтальный скроллинг */
}
  .notification-container {
    font-family: "Unbounded";
    font-weight: 1000;
    font-family: "Unbounded", sans-serif;
    color: #e4330f;
  }
  .random-text {
    position: fixed;
    font-size: 15px;
    animation: fade-in-out 15s linear infinite;
    font-family: "Unbounded", sans-serif;
  }

  .random-text:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  
  @keyframes fade-in-out {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .text {
    position: absolute;
  }
  
  .glitch {
    animation: glitch 0.3s linear infinite;
  }
  
  @keyframes glitch {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-1px, 1px);
    }
    40% {
      transform: translate(-1px, -1px);
    }
    60% {
      transform: translate(1px, 1px);
    }
    80% {
      transform: translate(1px, -1px);
    }
    100% {
      transform: translate(0);
    }
  }

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-left: 100px; 
  padding-right: 100px;
  position: absolute;

}
