.musician-card {
    font-family: "Unbounded", sans-serif;
    color: #e4330f;
  }
  
  .musician-image {
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .musician-name {
    margin-bottom: 10px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
  }
  
  .platform-links {
    display: flex;
    justify-content: center; /* Центрируем иконки по горизонтали */
    inline-size: 100%;
  }
  
  .platform-link {
    margin-right: 10px;
  }
  
  .platform-link:last-child {
    margin-right: 0;
  }
  
  .platform-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease; /* Добавляем плавный переход для изменения размера */
  }
  
  .platform-icon img {
    width: 20px;
    height: 20px;
  }
  
  .platform-icon:hover {
    transform: scale(1.2); /* Увеличиваем размер иконки при наведении */
  }